import type { ComputedRef } from 'vue';
import type { AhConfig } from '~/types/models/config';

/**
 * Composable to get ah-config from the admin
 */
export function useAhConfig() {
    const { apiClient } = useShopwareContext();
    const _storeAhConfig = useState<AhConfig | null>('ah-config', () => ref(null));

    const getAhConfig = async (): Promise<AhConfig | null> => {
        try {
            const response = await apiClient.invoke('getConfig get /ah-config');
            if (response) {
                _storeAhConfig.value = response as unknown as AhConfig;
            }
        } catch (error) {
            Logger.captureException(error);
        }
        return _storeAhConfig.value;
    };

    const algoliaIndexPrefix: ComputedRef<string> = computed(() => {
        return _storeAhConfig.value?.['core.config.algoliaIndexPrefix'] || '';
    });

    const algoliaIndexPostfix: ComputedRef<string> = computed(() => {
        return _storeAhConfig.value?.['core.config.algoliaIndexPostfix'] || '';
    });

    return {
        getAhConfig,
        algoliaIndexPrefix,
        algoliaIndexPostfix,
    };
}
