type Price2SpyCountry = {
    countryCode: string;
    countryName: string;
};

type Price2SpyDealer = {
    id: string;
    productNumber: string;
    countryCode: string;
    dealerName: string;
    url: string;
};

export function usePrice2Spy() {
    const { apiClient } = useShopwareContext();

    const dealers = useState<Price2SpyDealer[]>('price2SpyHandlers', () => []);
    const countries = useState<Price2SpyCountry[]>('price2SpyCountries', () => []);
    const nothingFound = computed(() => dealers.value.length === 0 && countries.value.length === 0);

    const getDealers = async (productNumber: string): Promise<Price2SpyDealer[]> => {
        if (!productNumber) return [];

        try {
            const response = await apiClient.invoke('getPrice2SpyDealers post /price2spy/{productNumber}', {
                productNumber,
            });

            if (response && Array.isArray(response)) {
                dealers.value = response;
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return dealers.value;
    };

    const getDealersByCountry = async (productNumber: string, countryCode: string): Promise<Price2SpyDealer[]> => {
        if (!productNumber) return [];

        try {
            const response = await apiClient.invoke(
                'getPrice2SpyDealersByCountry post /price2spy/onswitch/{productNumber}/{countryCode}',
                {
                    productNumber,
                    countryCode,
                },
            );

            if (response && Array.isArray(response)) {
                dealers.value = response;
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return dealers.value;
    };

    const getCountries = async (productNumber: string): Promise<Price2SpyCountry[]> => {
        if (!productNumber) return [];

        try {
            const response = await apiClient.invoke('getPrice2SpyCountries post /price2spy/countries/{productNumber}', {
                productNumber,
            });

            if (response?.items && Array.isArray(response.items)) {
                countries.value = response.items;
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return countries.value;
    };

    return {
        dealers,
        countries,
        nothingFound,
        getDealers,
        getCountries,
        getDealersByCountry,
    };
}
