import type { Schemas } from '#shopware';

export const useCustomInternationalization = () => {
    const { apiClient } = useShopwareContext();
    const { localeProperties } = useI18n();
    const { languageId, languageIdChain, refreshSessionContext } = useSessionContext();

    const languagesLoading = useState<boolean>('languages-loading', () => false);
    const languages = useState<Array<Schemas['Language']>>('languages', () => []);
    const contextLanguageId = computed(() => languageIdChain.value ?? languageId.value);
    const currentLanguage = computed<Schemas['Language'] | undefined>(() =>
        languages.value.find((language) => language.translationCode?.code === localeProperties.value.language),
    );

    const getLanguages = async (): Promise<void> => {
        if (languages.value.length || languagesLoading.value) return;

        try {
            languagesLoading.value = true;
            const languagesResponse = await apiClient.invoke('readLanguages post /language', {});
            languages.value = languagesResponse?.elements ?? [];
        } catch (error) {
            Logger.captureException(error);
        } finally {
            languagesLoading.value = false;
        }
    };

    const setLanguage = async (languageId: string): Promise<void> => {
        try {
            if (!languageId) return;

            await apiClient.invoke('updateContext patch /context', { languageId });
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const setCurrentLanguage = async (): Promise<void> => {
        const languageId = currentLanguage.value?.id;
        if (!languageId || languageId === contextLanguageId.value) return;

        await setLanguage(languageId);
        await refreshSessionContext();
    };

    const getLanguageCodeFromId = (languageId: string): string => {
        return languages.value.find((language) => language.id === languageId)?.translationCode?.code || '';
    };

    const getLanguageIdFromCode = (languageCode: string): string => {
        return languages.value.find((language) => language.translationCode?.code === languageCode)?.id || '';
    };

    return {
        languages: readonly(languages),
        currentLanguage: readonly(currentLanguage),
        contextLanguageId,
        getLanguages,
        setLanguage,
        setCurrentLanguage,
        getLanguageCodeFromId,
        getLanguageIdFromCode,
    };
};
