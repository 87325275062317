<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useSessionContext } from '~/composables/useSessionContext';

const props = defineProps<{
    error: {
        url: string;
        statusCode: number;
        statusMessage: string;
        message: string;
        stack: string;
    };
}>();

const { refreshSessionContext } = useSessionContext();
await useAsyncData('sessionContext', () => refreshSessionContext());

const localePath = useLocalePath();
const errorDetails = getErrorPageText(props.error);

const layout = computed(() => {
    if (props.error.statusCode >= 500 && props.error.statusCode < 600) {
        return 'minimal';
    }

    return 'default';
});

const breakpoints = useBreakpoints(breakpointsTailwind);
provide('breakpoints', breakpoints);

const { getLanguages, setCurrentLanguage } = useCustomInternationalization();
await useAsyncData('languages', async () => {
    const languages = await getLanguages();
    await setCurrentLanguage();

    return languages;
});

const { getAhConfig } = useAhConfig();
await useAsyncData('ahConfiguration', async () => getAhConfig());

useAppHead();
useNotifications();
useModal();
useOffcanvas();
useHeaderState();

if (layout.value !== 'minimal') {
    useAppMenu(true);
}
</script>

<template>
    <NuxtLayout :name="layout">
        <div
            class="py-3 md:py-20 md:px-32 lg:px-24 lg:py-24 items-center flex justify-center flex-col-reverse lg:flex-row"
        >
            <div class="flex flex-col items-center justify-center my-8">
                <div class="container text-center">
                    <h1 class="mb-8">
                        <span class="sr-only">{{ $t('notifications.danger') }}</span>
                        {{ error.message === 'notAllowed' ? $t('errors.notAllowedHeader') : errorDetails.errorHeading }}
                    </h1>
                    <p class="heading-4 mt-4 mb-8">
                        {{ error.message === 'notAllowed' ? $t('errors.notAllowedMsg') : errorDetails.errorMessage }}
                    </p>
                    
                    <SharedButton
                        v-if="error?.message === 'notLoggedIn'"
                        :to="localePath('/customer/login')"
                        color="brand-primary"
                        class="mb-3"
                    >
                        {{ $t('loginButton') }}
                    </SharedButton>
                    <br />

                    <SharedButton
                        :to="localePath('/')"
                        color="brand-primary"
                    >
                        {{ $t('home') }}
                    </SharedButton>
                </div>
            </div>
        </div>
    </NuxtLayout>

    <SharedModal />
    <SharedOffcanvas />
</template>

<style scoped>
:deep(pre) {
    white-space: pre-wrap;
}
</style>
