import type { RequestReturnType } from '#shopware';
import type { Ref } from 'vue';
import type { ProductPrice } from '~/types/models/product/productPrice';
import { Price } from '~/helpers/Price';

export type UseProductPricesReturn = {
    getProductPrices(): Promise<void>;
    cleanPriceStorage(): void;
    formatPrice(price: number, maxDecimalDigits?: number): string;
    formatDisplayPrice(price: number, maxDecimalDigits?: number): string;
    dateWhenTimeStampInvalid: Ref<number | null>;
    productPrices: any;
    isLoading: Ref<boolean>;
    getPrice(number: any): ProductPrice;
    cleanOldShopStore: () => void;
};

const RETRY_TIME = 60000;

/**
 * Composable to manage the price storage
 * @public
 * @category Productlistings
 */
export function useProductPrices(appCall?: boolean): UseProductPricesReturn {
    const { apiClient } = useShopwareContext();
    const { isLoggedIn } = useUser();
    const { languageIdChain } = useSessionContext();
    const dateWhenTimeStampInvalid: Ref<number | null> = ref(null);
    const isLoading = useState('product-prices-loading', () => ref(false));
    const priceStorageKey = 'product-prices';
    const priceDisplayCurrencyStorageKey = 'product-prices-display-currency';
    const priceLanguageStorageKey = 'product-prices-language-id';
    const { sessionContext } = useSessionContext();
    const { currentLanguage } = useCustomInternationalization();

    const productPricesStorage = useState<RequestReturnType<'getPricelistOfUser'> | null>('product-prices', () =>
        shallowRef(null),
    );
    const productPrices = computed(() => productPricesStorage.value);

    if (appCall) {
        onBeforeMount(() => {
            setInterval(() => {
                if (!_timeStampIsValid(productPrices.value)) {
                    dateWhenTimeStampInvalid.value = Date.now();
                }
            }, RETRY_TIME);
        });
    }

    async function getProductPrices(): Promise<void> {
        const pricesFromLocalStorage = JSON.parse(localStorage.getItem(priceStorageKey) || 'null');
        const storageValid =
            _timeStampIsValid(pricesFromLocalStorage) && _priceLanguageIsValid() && _priceDisplayCurrencyIsValid();

        if (!isLoggedIn.value) {
            cleanPriceStorage();
            return;
        }

        if (storageValid) {
            productPricesStorage.value = pricesFromLocalStorage;
        } else {
            await _getFromApi();
        }
    }

    const formatPrice = (price: number, maxDecimalDigits: number = 2): string => {
        return Price.getFormattedPrice(
            price,
            currentLanguage.value?.translationCode?.code,
            sessionContext.value?.currency?.isoCode,
            maxDecimalDigits,
        );
    };
    const formatDisplayPrice = (price: number, maxDecimalDigits: number = 2): string => {
        return Price.getFormattedPrice(
            price,
            currentLanguage.value?.translationCode?.code,
            sessionContext.value?.displayCurrency?.isoCode,
            maxDecimalDigits,
        );
    };

    const cleanPriceStorage = () => {
        productPricesStorage.value = null;
        localStorage.removeItem(priceStorageKey);
    };

    const cleanOldShopStore = () => {
        if (!import.meta.client) return;

        const keysToDelete = ['ahPriceTtl', 'ahUserArticlePrices-', 'ahGlobalArticlePrices', 'customerArticles-'];

        for (const storageKey of Object.keys(localStorage)) {
            if (storageKey) {
                for (const keyToDelete of keysToDelete) {
                    if (storageKey?.toLowerCase().includes(keyToDelete.toLowerCase())) {
                        localStorage.removeItem(storageKey);
                    }
                }
            }
        }
    };

    const getPrice = (number: any): ProductPrice => {
        return productPrices?.value?.articles?.[number] || { unbuyable: true };
    };

    const _setToStorage = (priceList: RequestReturnType<'getPricelistOfUser'>) => {
        productPricesStorage.value = priceList;
        localStorage.setItem(priceStorageKey, JSON.stringify(priceList));
    };

    const _cleanPriceListResponse = (
        response: RequestReturnType<'getPricelistOfUser'>,
    ): RequestReturnType<'getPricelistOfUser'> => {
        const articles: Record<string, ProductPrice> = {};
        for (const [productNumber, productPrice] of Object.entries(response.articles || {})) {
            if (productPrice.unbuyable || productPrice.price) {
                delete productPrice.apiAlias;
                articles[productNumber] = productPrice;
            }
        }

        return { ...response, articles };
    };

    const _getFromApi = async () => {
        isLoading.value = true;

        const priceListResponse = await apiClient.invoke('getPricelistOfUser get /pricelist');

        if (priceListResponse.success) {
            const cleanedPriceList = _cleanPriceListResponse(priceListResponse);
            _setToStorage(cleanedPriceList);
            _savePriceLanguageToStorage();
            _savePriceDisplayCurrencyToStorage();
        }

        isLoading.value = false;
    };

    const _timeStampIsValid = (priceList: RequestReturnType<'getPricelistOfUser'> | null) => {
        if (priceList === null) {
            return false;
        } else {
            return new Date(priceList.expiredDateTime ?? Date.now()) > new Date(Date.now());
        }
    };

    const _savePriceLanguageToStorage = () => {
        localStorage.setItem(priceLanguageStorageKey, languageIdChain.value);
    };

    const _getPriceLanguageFromStorage = () => {
        return localStorage.getItem(priceLanguageStorageKey);
    };

    const _priceLanguageIsValid = () => {
        return languageIdChain.value === _getPriceLanguageFromStorage();
    };

    const _savePriceDisplayCurrencyToStorage = () => {
        localStorage.setItem(priceDisplayCurrencyStorageKey, sessionContext?.value?.displayCurrency?.isoCode);
    };

    const _getPriceDisplayCurrencyFromStorage = () => {
        return localStorage.getItem(priceDisplayCurrencyStorageKey);
    };

    const _priceDisplayCurrencyIsValid = () => {
        return sessionContext?.value?.displayCurrency?.isoCode === _getPriceDisplayCurrencyFromStorage();
    };

    return {
        getProductPrices,
        cleanPriceStorage,
        formatPrice,
        formatDisplayPrice,
        dateWhenTimeStampInvalid,
        productPrices,
        isLoading,
        getPrice,
        cleanOldShopStore,
    };
}
