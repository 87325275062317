<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useSessionContext } from '#imports';
import { useSchemaOrg, defineWebSite, defineSearchAction } from '@unhead/schema-org/vue';

useAppHead();
useXdebug();

const { locale, localeCodes } = useI18n();
const { refreshCart } = useCart();

const { refreshSessionContext, sessionContext, languageIdChain } = useSessionContext();
await refreshSessionContext();

const route = useRoute();
const { setSeoPaths, changeLanguageOnContextChange } = useLanguageSelect();
const setI18nParams = useSetI18nParams();

const nuxtApp = useNuxtApp();
watch(
    () => route.path,
    async () => {
        if (
            route.name &&
            !route.name.toString().startsWith('slug___') &&
            !route.name.toString().startsWith('customer-')
        ) {
            await setSeoPaths();

            nuxtApp.runWithContext(() => {
                setI18nParams({});
            });
        }
    },
    { immediate: true },
);

const { isLoggedIn, user } = useUser();
const displayCurrencyId = computed(() => sessionContext.value?.displayCurrency.id);

const { getLanguages, setCurrentLanguage } = useCustomInternationalization();
// both calls need to be done without useAsyncData, because they need to run on server and client.
// else there will be an issue with the session/language
await getLanguages();
await setCurrentLanguage();

const { getAhConfig } = useAhConfig();
await useAsyncData('ahConfiguration', () => getAhConfig());

const { getProductPrices, dateWhenTimeStampInvalid, cleanOldShopStore } = useProductPrices(true);
cleanOldShopStore();
useLazyAsyncData('productPrices', () => getProductPrices(), {
    server: false,
    watch: [isLoggedIn, dateWhenTimeStampInvalid, displayCurrencyId, languageIdChain],
});

useBreadcrumbs();
useNotifications();
useModal();
useOffcanvas();
useHeaderState();
useAppMenu(true);

useHead({
    templateParams: {
        schemaOrg: {
            host: baseDomain() + '/' + locale.value,
            path: '/',
            // @ts-ignore
            inLanguage: localeCodes.value,
        },
    },
});

const { shopTitle } = useAppConfig();
useSchemaOrg([
    defineWebSite({
        name: shopTitle,
        potentialAction: [
            defineSearchAction({
                target: '/search?query={search_term_string}',
            }),
        ],
    }),
]);

const breakpoints = useBreakpoints(breakpointsTailwind);
provide('breakpoints', breakpoints);

const { setAuthenticatedUserToken } = useAlgoliaTracking();
if (import.meta.client) {
    watch(
        isLoggedIn,
        () => {
            setAuthenticatedUserToken(user.value?.extensions?.attributes.globalAccountId);
        },
        {
            immediate: true,
        },
    );

    const visibility = useDocumentVisibility();
    watch(visibility, async () => {
        if (visibility.value) {
            await changeLanguageOnContextChange();
        }
    });
}

const { getContactInformation } = useContactInformation();
const isCheckoutCart = computed(() => route.name?.toString()?.startsWith('checkout-cart'));

if (!isCheckoutCart.value) {
    useLazyAsyncData('cart', () => refreshCart(), {
        server: false,
        watch: [isLoggedIn],
    });

    useLazyAsyncData('contactInformation', () => getContactInformation(), {
        server: false,
        watch: [isLoggedIn],
    });
}
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <SharedModal />
    <SharedOffcanvas />
</template>

<style lang="scss">
@use './assets/scss/app';
</style>
