import { ref, type Ref, watch } from 'vue';
import type { AppMenu, AppMenuResponseJson, AppMenuResponse } from '~/types/models/appMenu';

export type UseMenuReturn = {
    getAppMenu(): Promise<AppMenu | null>;
    appMenu: Ref<AppMenu>;
    error: Ref<boolean>;
    _validateAppMenuData(response: AppMenuResponse): AppMenu;
};

/**
 * Composable to manage the app menu
 * @public
 * @category Account
 */
export function useAppMenu(appCall = false): UseMenuReturn {
    const { apiClient } = useShopwareContext();
    const appMenu: Ref<AppMenu> = useState('app-menu', () => ref(null));
    const error = ref(false);
    const { isLoggedIn } = useUser();

    // reset app menu on user login/logout
    if (appCall) {
        watch(isLoggedIn, () => {
            appMenu.value = null;
        });
    }

    async function getAppMenu(): Promise<AppMenu | null> {
        const { currentLanguage } = useCustomInternationalization();

        try {
            if (appMenu.value) return appMenu.value;
            if (!currentLanguage.value?.id) return null;

            const response: unknown = await apiClient.invoke('loadServiceMenu get /service-menu?activeLanguageId', {
                activeLanguageId: currentLanguage.value.id,
            });

            appMenu.value = _validateAppMenuData(response as AppMenuResponse);
            return appMenu.value;
        } catch (e) {
            error.value = true;
            Logger.captureException(e);
        }
        return null;
    }

    function _validateAppMenuData(response: AppMenuResponse): AppMenu {
        const appMenuJson = response?.appMenuJson;

        if (!appMenuJson) return null;

        let appMenu: AppMenuResponseJson | null = null;
        try {
            appMenu = JSON.parse(appMenuJson);
        } catch (_e) {
            return null;
        }

        if (!appMenu || !appMenu.data || Object.keys(appMenu.data).length === 0) return null;

        // Transform data into an array and add the object key as an id to each item
        return Object.keys(appMenu.data).map((key) => {
            const item = appMenu?.data[key];
            item.id = key;
            return item;
        });
    }

    return {
        getAppMenu,
        appMenu,
        error,
        _validateAppMenuData,
    };
}
