import { reactive, toRefs, type Ref } from 'vue';

export type UseHeaderStateReturn = {
    headerVisible: Ref<boolean>;
    changeHeaderVisibility: (visible: boolean) => void;
    headerHeight: Ref<number | undefined>;
    setHeaderHeight: (height: number | undefined) => void;
};

const state = reactive({
    headerVisible: true,
});

export function useHeaderState(): UseHeaderStateReturn {
    const { headerVisible } = toRefs(state);

    const headerHeight = useState<number | undefined>('headerHeight', () => 0);

    const setHeaderHeight = (height: number | undefined) => {
        if (height === headerHeight.value) return;
        headerHeight.value = height;
    };

    const changeHeaderVisibility = (visible: boolean) => {
        state.headerVisible = visible;
    };

    return {
        headerVisible,
        changeHeaderVisibility,
        headerHeight,
        setHeaderHeight,
    };
}
