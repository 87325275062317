import { hasAlgoliaConsent } from '~/helpers/Consent';

enum AlgoliaTrackingEvents {
    view = 'Product Viewed',
    categoryView = 'Category Viewed',
    click = 'Product Clicked',
    categoryClick = 'Category Clicked',
    add = 'Product Added To Cart',
    purchase = 'Product Purchased',
    conversion = 'Product Conversion',
    filterView = 'Filter Viewed',
    filterClick = 'Filter Clicked',
}

type AlgoliaIndexType = 'product' | 'category';

type TrackProductParams = {
    objectIDs: string[];
    queryID: string;
    positions: number[];
    type: AlgoliaIndexType;
    objectData?: AlgoliaTrackingObjectData[];
    value?: number | string;
    currency?: string;
    filters?: string[];
};

type AlgoliaTrackingObjectData = {
    price?: number;
    discount?: number;
    quantity?: number;
    queryID?: string;
};

export function useAlgoliaTracking() {
    const aa: any = inject('aa', () => {});
    const queryStorageKey = 'algoliaCartData';
    const currentPageQueryId = useState('currentPageQueryId', () => ref(''));

    const { categoryIndex, productIndex } = useAlgolia();

    const trackAlgoliaObjectView = ({ objectIDs, type = 'product' }: Partial<TrackProductParams>) => {
        if (!objectIDs?.length) return;

        if (hasAlgoliaConsent()) {
            aa('viewedObjectIDs', {
                index: type === 'product' ? productIndex.value : categoryIndex.value,
                eventName: type === 'product' ? AlgoliaTrackingEvents.view : AlgoliaTrackingEvents.categoryView,
                objectIDs: objectIDs,
            });
        }
    };

    const trackAlgoliaObjectClick = ({
        objectIDs,
        queryID,
        type = 'product',
        positions,
    }: Partial<TrackProductParams>) => {
        if (!objectIDs?.length) return;

        if (hasAlgoliaConsent()) {
            const eventName = queryID ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs';

            aa(eventName, {
                index: type === 'product' ? productIndex.value : categoryIndex.value,
                eventName: type === 'product' ? AlgoliaTrackingEvents.click : AlgoliaTrackingEvents.categoryClick,
                objectIDs,
                ...(queryID && {
                    queryID,
                    positions,
                }),
            });
        }
    };

    const saveAlgoliaQueryToLocalStorage = (queryID: string | undefined, objectID: string) => {
        if (!queryID) return;

        try {
            const cartData: Array<{ queryID: string; objectID: string }> = JSON.parse(
                localStorage.getItem(queryStorageKey) || '[]',
            );

            const itemExists = cartData.some((item) => item.queryID === queryID && item.objectID === objectID);

            if (!itemExists) {
                cartData.push({ queryID, objectID });
                localStorage.setItem(queryStorageKey, JSON.stringify(cartData));
            }
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const resetAlgoliaQueryStorage = () => {
        localStorage.setItem(queryStorageKey, '[]');
    };

    const trackAlgoliaObjectAddToCart = ({ objectIDs, queryID }: Partial<TrackProductParams>) => {
        if (!objectIDs?.length) return;

        if (hasAlgoliaConsent()) {
            const eventName = queryID ? 'addedToCartObjectIDsAfterSearch' : 'addedToCartObjectIDs';

            saveAlgoliaQueryToLocalStorage(queryID, objectIDs[0]);

            aa(eventName, {
                index: productIndex.value,
                eventName: AlgoliaTrackingEvents.add,
                objectIDs,
                ...(queryID && {
                    queryID,
                }),
            });
        }
    };

    const trackAlgoliaObjectPurchase = ({ objectIDs, objectData, value, currency }: Partial<TrackProductParams>) => {
        if (!objectIDs?.length) return;

        if (hasAlgoliaConsent()) {
            const hasQueryIds = objectData?.some((data) => data?.queryID);
            const eventName = hasQueryIds ? 'purchasedObjectIDsAfterSearch' : 'purchasedObjectIDs';

            aa(eventName, {
                index: productIndex.value,
                eventName: AlgoliaTrackingEvents.purchase,
                objectIDs,
                objectData,
                value,
                currency,
            });
        }
    };

    const trackAlgoliaObjectConversion = ({ objectIDs, queryID }: Partial<TrackProductParams>) => {
        if (!objectIDs?.length) return;

        if (hasAlgoliaConsent()) {
            const eventName = queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs';

            aa(eventName, {
                index: productIndex.value,
                eventName: AlgoliaTrackingEvents.conversion,
                objectIDs,
                ...(queryID && {
                    queryID,
                }),
            });
        }
    };

    // user data is not available during nuxt plugin init, so we set it in composable
    const setAuthenticatedUserToken = (token: string | undefined) => {
        if (!token) return;

        if (hasAlgoliaConsent()) {
            aa('setAuthenticatedUserToken', token);
        }
    };

    const trackAlgoliaViewedFilters = ({ filters }: Partial<TrackProductParams>) => {
        aa('viewedFilters', {
            index: productIndex.value,
            eventName: AlgoliaTrackingEvents.filterView,
            filters: filters,
        });
    };

    const trackAlgoliaClickedFilters = ({ filters }: Partial<TrackProductParams>) => {
        aa('clickedFilters', {
            index: productIndex.value,
            eventName: AlgoliaTrackingEvents.filterClick,
            filters: filters,
        });
    };

    return {
        trackAlgoliaObjectView,
        trackAlgoliaObjectClick,
        trackAlgoliaObjectAddToCart,
        trackAlgoliaObjectConversion,
        trackAlgoliaObjectPurchase,
        trackAlgoliaViewedFilters,
        trackAlgoliaClickedFilters,
        setAuthenticatedUserToken,
        resetAlgoliaQueryStorage,
        currentPageQueryId,
    };
}
